<template>
  <div class="test">
    <div class="container">
      
      <div class="test-div">
        <journey-map-edit />
      </div>

    </div>
  </div>
</template>


<script>
import JourneyMapEdit from '@/components/JourneyMapEdit.vue';

export default {
  name: 'Test',
  components: {
    JourneyMapEdit,
  },
};
</script>


<style lang="scss" scoped>
.test-div {
  width: 400px;
  height: 400px;
}
</style>
