<template>
  <div class="journey-map-edit">
    <l-map @ready="mapReady" class="l-map" ref="leafmap" >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    </l-map>
  </div>
</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'JourneyMapEdit',
  data() {
    return {
      journey: null,
      mapObject: null,
      markerList: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  components: {
    LMap,
    LTileLayer,
  },
  async mounted() {
  },
  methods: {
    async mapReady(map) {
      console.log(map)
      console.log('map ready');
      await this.addOptionMilestones(50);
    },
    async addOptionMilestones(milestoneSerial) {
      const milestones = await this.$store.dispatch('api/postConnectedMilestonePromise', {
        milestoneSerial,
      });
      for (const m of milestones) {
        const tempMarker = L.marker([m.lat, m.lng]);
        tempMarker.addTo(this.$refs.leafmap.mapObject);
        this.markerList.push(tempMarker);
      }
      console.log(milestones);
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .journey-map-edit {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .l-map {
    width: 100%;
    height: 100%;
  }
</style>

